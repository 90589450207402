import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class InfraCostSheetService {
    async createCostSheet(data) {
        try {
            const res = await axios.post(API_URL + 'infra/costsheet', data,
        { 
            headers: AuthService.authHeader()
        });
        console.log(res)
        return res;
        }
        catch (err) {
            console.log(err.response)
            return err.response;
        }
        
    }

    async getCostSheet(id) {
        try {
            const res = await axios.get(API_URL + 'infra/costsheet/' + encodeURIComponent(id),
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getAllCostSheets(inquiry_id) {
        try {
            const res = await axios.get(API_URL + 'infra/inquiry/costsheet/' + encodeURIComponent(inquiry_id),
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async updateCostSheet(id,data) {
        try {
            const res = await axios.put(API_URL + 'infra/costsheet/' + id, data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async deleteCostSheet(id) {
        try {
            const res = await axios.delete(API_URL + 'infra/costsheet/:' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

}

export default new InfraCostSheetService();