import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}





class AdminConfigService {
    async create(data) {
        try {
            const res = await axios.post(API_URL + 'admin/config', data,
        { 
            headers: AuthService.authHeader()
        });
        //console.log(res.data)
        return res.data;
        }
        catch (err) {
            console.log(err.response)
            return err.response;
        }
        
    }

    async getConfig(module_name, param_name) {
        try {
            const res = await axios.get(API_URL + 'admin/config/' + module_name + '/' + param_name,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    
    async getConfigPowerBI(module, role, empId) {
        try {
            const res = await axios.get(API_URL + 'admin/powerbi/config?module=' + module + '&role=' + role + '&empId=' + empId,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async getConfigExpense() {
        try {
            const res = await axios.get(API_URL + 'admin/config/expense/',
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getConfigInfra() {
        try {
            const res = await axios.get(API_URL + 'admin/config/infra',
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getATSDropdownList() {
        try {
            const res = await axios.get(API_URL + 'admin/config/menu',
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getJobsDropdownList() {
        try {
            const res = await axios.get(API_URL + 'admin/config/jobs',
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getProjectDropdownList() {
        try {
            const res = await axios.get(API_URL + 'admin/config/project',
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async update(id) {
        try {
            const res = await axios.put(API_URL + 'admin/config/:' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async delete(id) {
        try {
            const res = await axios.delete(API_URL + 'admin/config/:' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

}

export default new AdminConfigService();